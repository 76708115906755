<template>
  <div class="transactions">
    <v-row>
      <v-col>
        <breadcrumb :items="breadcrumbs" />
      </v-col>
    </v-row>
    <div style="height: 28px">
      <h3
        v-show="!isLoading"
        class="font-weight-light"
      >
        Some information about meter <span class="font-weight-medium">{{ details.number }}</span>
      </h3>
      <vue-skeleton-loader
        v-show="isLoading"
        :width="350"
        :height="21"
      />
    </div>
    <br>
    <user-details-card
      :user="details.user"
      :is-loading="isLoading"
    />
    <br>
    <meter-details-card
      :meter="details"
      :is-loading="isLoading"
    />
    <br>
    <br>
    <div v-if="details.type !== null && details.type.name == 'Prepaid'">
      <meter-tokens-table
        :meter-token-url="meterTokenUrl"
        :clickable-rows="false"
        :show-add-meter-dialog="showAddMeterDialog"
        @add-meter-dialog-open="showAddMeterDialog = true"
        @add-meter-dialog-close="showAddMeterDialog = false"
      />
      <br>
      <br>
    </div>
    <div v-else>
      <meter-readings-table
        :meter-reading-url="meterReadingUrl"
        :clickable-rows="false"
        :for-specific-customer="true"
        :show-add-meter-dialog="showAddMeterDialog"
        @add-meter-dialog-open="showAddMeterDialog = true"
        @add-meter-dialog-close="showAddMeterDialog = false"
      />
      <br>
      <br>
      <meter-reading-statistics-card
        v-if="can('statistics-list')"
        :meter-id="meterId"
      />
      <br>
      <br>
      <daily-meter-readings-details-card :meter-id="meterId" />
    </div>
  </div>
</template>
<script>
import axios from 'axios'
import { mapGetters } from 'vuex'
import VueSkeletonLoader from 'skeleton-loader-vue'
import UserDetailsCard from '@/components/details-card/UserDetailsCard.vue'
import MeterDetailsCard from '@/components/details-card/MeterDetailsCard.vue'
import MeterReadingsTable from '@/components/tables/MeterReadingsTable.vue'
import MeterTokensTable from '@/components/tables/MeterTokensTable.vue'
import DailyMeterReadingsDetailsCard from '@/components/details-card/DailyMeterReadingsDetailsCard.vue'
import MeterReadingStatisticsCard from '@/components/details-card/MeterReadingStatisticsCard.vue'
import BreadCrumb from '@/components/partials/BreadCrumb.vue'
import hasPermission from '@/mixins/hasPermission'

export default {
  components: {
    breadcrumb: BreadCrumb,
    'user-details-card': UserDetailsCard,
    'meter-details-card': MeterDetailsCard,
    'meter-readings-table': MeterReadingsTable,
    VueSkeletonLoader,
    MeterReadingStatisticsCard,
    MeterTokensTable,
    DailyMeterReadingsDetailsCard,
  },
  mixins: [hasPermission],
  data() {
    return {
      search: '',
      details: {
        user: {}, meter: {}, type: { name: '' }, transaction: [],
      },
      isLoading: false,
      showAddMeterDialog: false,
      meterId: this.$route.params.id,
      breadcrumbs: [
        {
          text: 'Dashboard',
          disabled: false,
          to: { name: 'dashboard' },
        },
        {
          text: 'Meters',
          disabled: false,
          to: { name: 'meters' },
        },
      ],
    }
  },
  computed: {
    ...mapGetters(['selectedStation']),
    meterReadingUrl() {
      return `meter-readings?station_id=${this.selectedStation}&meter_id=${this.$route.params.id}`
    },
    meterTokenUrl() {
      return `meter-tokens?station_id=${this.selectedStation}&meter_id=${this.$route.params.id}`
    },
  },
  mounted() {
    this.getDetails()
    if (this.meterId) {
      this.breadcrumbs.push(
        {
          text: this.meterId,
          disabled: true,
          to: { name: 'more-customer-details' },
        },
      )
    }
  },
  methods: {
    getDetails() {
      this.isLoading = true
      axios
        .get(`meters/${this.meterId}`)
        .then(response => {
          this.details = response.data
          this.isLoading = false
        })
        .catch(error => {
          this.isLoading = false
          this.$notification.error(error.response.data.message)
        })
    },
  },
}
</script>
